import { Uploader } from "uploader"; // Installed by "react-uploader".
//import { UploadButton } from "react-uploader";
import axios from 'axios'
// Initialize once (at the start of your app).
// const uploader = Uploader({
//   apiKey: "free" // Get production API keys from Upload.io
// });

// // Configuration options: https://upload.io/uploader#customize
// const options = { multi: true };

const FileUpload = (props) => {
  <div>
  {/* <UploadButton uploader={uploader}
                options={options}
               // onComplete={files => alert(files.map(x => x.fileUrl).join("\n"))}
               onComplete={upload}
               >
    {({onClick}) =>
      <button onClick={onClick}>
        Upload a file...
      </button>
   }
 </UploadButton> */}
 
 </div>

const handleupload=(e)=>
{
  props.upload(e)
}
return <div className="row g-3 m-2"> 
<label className="form-label" htmlFor="customFile">Please Upload Your Image</label>
<input type="file" className="form-control" id="customFile" onChange={handleupload} /></div>
};
const hh= async (files) =>{
  console.log(files.target.files[0]);
  const formData = new FormData();
  formData.append("file", files.target.files[0]);
  const paintingRequest={FirstName:"evan",LastName:"Dangol",Class:6,Country:"Nepal"};
  //formData.append('paintingRequest',paintingRequest);
   formData.append('paintingRequest',JSON.stringify(paintingRequest));
//formData.append('paintingRequest1',"hello");
  console.log(formData);
  try {
    const response = await axios({
      method: "post",
      url: "https://localhost:4718/api/painting/upload",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  } catch(error) {
    console.log(error)
  }
  
}
export default FileUpload