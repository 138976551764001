import React from 'react'
import Order from './Order'

const OrderPage = () => {
  return (
    <>
    <div className='container'>
    <h1>This is order Page</h1>
      <Order />
      <h1>This is order Page</h1>
      </div>
    </>
  )
}

export default OrderPage
