import React, { Component } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../assets/styles/carousel.css'
class Banner extends Component {
    render() {
        return (
            <Carousel autoPlay='true' showThumbs={0} thumbWidth={0} className="slider">
                <div className='item'>
                    <img src="assets/images/h1.jpg" alt='x' />
                    {/* <p className="legend">Tishaz</p> */}
                </div>
                <div className='item'>
                <img src="assets/images/h2.jpg" alt='y' />
                    {/* <p className="legend">Tishaz</p> */}
                </div>
                <div className='item'>
                <img src="assets/images/h4.jpg" alt='z' />
                    {/* <p className="legend">Tishaz</p> */}
                </div>
            </Carousel>
        );
    }
};
export default Banner
//ReactDOM.render(<DemoCarousel />, document.querySelector('.demo-carousel'));

// Don't forget to include the css in your page

// Using webpack or parcel with a style loader
// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

// Using html tag:
// <link rel="stylesheet" href="<NODE_MODULES_FOLDER>/react-responsive-carousel/lib/styles/carousel.min.css"/>