import getStripe from '../lib/getStripe';

export default function CheckOut(paymentInfo) {
  console.log(paymentInfo);

  let quantity=0;
  let priceId='';
  console.log(typeof(paymentInfo));
  if (typeof(paymentInfo) != 'undefined' || paymentInfo != null || paymentInfo!='undefined') 
  {
   quantity=paymentInfo.paymentInfo?.quantity;
   priceId=paymentInfo.paymentInfo?.priceId;
 
  }
  async function handleCheckout() {
    console.log(paymentInfo);
    if(paymentInfo)
    {
      console.log(quantity);
      if(typeof(quantity)=='undefined')
      {
        alert("this dimension is not supported { choose 4X4 or 8X8}");
        return;
      }
      return;
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: priceId,
          quantity: quantity,
        }
      ],
      mode: 'payment',
      successUrl: `http://localhost:3000/success`,
      cancelUrl: `http://localhost:3000/cancel`,
      customerEmail: 'customer@email.com',
    });
    console.warn(error.message);
  }
   
  }

  return <button disabled={paymentInfo.priceId} className='btn btn-primary' onClick={handleCheckout}>Order Now</button>;
}