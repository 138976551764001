import React, { Component } from 'react';
import {Routes , Route,  BrowserRouter as Router } from "react-router-dom" 
import About from './Components/About';
import Orders from './Components/Admin/Order';
import Banner from './Components/Banner';
import CheckOut from './Components/CheckOut';
import CheckOut1 from './Components/CheckOut1';
import MainMenu from './Components/MainMenu';
import Main from './Main';
//import StripeBtn from './Components/StripeBtn';
import OrderPage from './Components/OrderPage';
import Gallary from './Components/pages/Gallary';
import Blog from './Components/Blog';
import Footer from './Components/Footer';

class App1 extends Component {
  render() {
    return (
      <div>
      <MainMenu />
      <Banner />
      
      <Router>
      <Routes>
        {/* Method 1: Using `useEffect()` as a redirect */}
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/checkout" element={<CheckOut />} />
        <Route path="/gallary" element={<Gallary />} />
        <Route path="/orders" element={<Orders />} />
      </Routes>
    </Router>
    <Footer />
    </div>
    );
  }
}
export default App1;