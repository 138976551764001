import React from 'react'
// import Banner from './Components/Banner'
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import './assets/styles/style.css'
import './assets/styles/main.css'
//import NavP from './Components/NavP'
// import MainMenu from './Components/MainMenu'
const Main = () => {
  return (
    <>
      <div className="container">
        <div className="row mt-3 ">
          <div className="col-sm card p-3 m-1">
          <p className='para'>Want to turn your photos into a piece of art? This painting tool adds an oil painting effect to your photos and makes them realistic oil paintings. Channel your inner Da Vinchi by using this tool to start photo painting online.
          </p> </div>
          <div className="col-sm card p-3 m-1">
          <p className='para'>  Want to turn your photos into a piece of art? This painting tool adds an oil painting effect to your photos and makes them realistic oil paintings. Channel your inner Da Vinchi by using this tool to start photo painting online.
          </p>
          </div>
          <div className="col-sm card p-3 m-1">
          <p className='para'>   Want to turn your photos into a piece of art? This painting tool adds an oil painting effect to your photos and makes them realistic oil paintings. Channel your inner Da Vinchi by using this tool to start photo painting online.
          </p></div>
        </div>
        
        <div className="wrapper">
          <img src="https://source.unsplash.com/random/600x600?water" alt="" />
          <img src="https://source.unsplash.com/random/600x600?summer" alt="" />
          <img src="https://source.unsplash.com/random/600x600?plants" alt="" />
          <img src="https://source.unsplash.com/random/600x600?snow" alt="" />
          <img src="https://source.unsplash.com/random/600x600?roses" alt="" />
          <img src="https://source.unsplash.com/random/600x600?sky" alt="" />
          <img src="https://source.unsplash.com/random/600x600?nature" alt="" />
          <img src="https://source.unsplash.com/random/600x600?blossom" alt="" />
          <img src="https://source.unsplash.com/random/600x600?ice" alt="" />
          <img src="https://source.unsplash.com/random/600x600?spring" alt="" />
        </div>
      </div>
    </>
  )
}

export default Main
