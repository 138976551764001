import React from 'react'
//import { FormControl, FormControlLabel, RadioGroup, FormLabel, Radio } from '@mui/material';
import FileUpload from './FileUpload'
import { useState } from 'react';
import CheckOut from './CheckOut';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../assets/styles/mainblog.css'
import '../assets/styles/variables.css'
import '../assets/styles/order.css'
const Order = () => {
    const [isShown, setIsShown] = useState(false);
    const [ptype, setPGender] = useState();
    const [Photo, setPhoto] = useState();
    const [paymentInfo, setPaymentInfo] = useState(/* { priceId: 'price_1MfxZKFYKOC89HWTrALly7KX', quantity: 1 } */)
    const handleChange = e => {
        setIsShown(false);
        const target = e.target;
        if (target.checked) {
            setPGender(target.value);
            setIsShown(true);
            switch (target.value) {
                case '4':
                    setPaymentInfo({ priceId: 'price_1MciDkFYKOC89HWTrIzosx54', quantity: 1,file:Photo })
                    break;
                case '8':
                    setPaymentInfo({ priceId: 'price_1MfxZKFYKOC89HWTrALly7KX', quantity: 1,file:Photo })
                    break;
                default:
                    break;
            }
        }



    };
    const Ordernow = () => {

        console.log(ptype);
    }
    const upload = (e) => {
        console.log(e.target.files[0]);
        setPhoto(e.target.files[0])
    }

    return (
        <>
        <div className='container'>
            <Container  className='card mt-3'>
                <Row className='wrap'>
                    <Col xs lg="5" sm="10" className='c1'> <FileUpload upload={upload} /></Col>

                    <Col className=' c2' lg="5" sm="10"><div className='card m-2 p-2'>
                        <div className="form-check">
                            <input onChange={handleChange} value='4' checked={ptype === '4'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                4X4
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={handleChange} value='6' checked={ptype === '6'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                6X6
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={handleChange} value='8' checked={ptype === '8'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                8X8
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={handleChange} value='16' checked={ptype === '16'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                            <label className="form-check-label" htmlFor="flexRadioDefault4">
                                16X16
                            </label>
                        </div>
                    </div></Col>
                    
                    {/* <Col> {isShown && (
                        <div className='m-3'>
                            <CheckOut paymentInfo={paymentInfo} />
                        </div>
                    )}</Col> */}
                     <Col sm="10" lg="2"> 
                        <div className='m-3 c3'>
                            <CheckOut paymentInfo={paymentInfo} />
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col> {Photo && (
                        <div className='card m-3 imguploaded' style={{ width: 500,textAlign:'left' }}>
                            <img style={{ width: 'auto', height: 300, objectFit: 'contain'  }}
                                src={URL.createObjectURL(Photo)}
                                alt="Thumb" className='img-fluid'
                                
                            />

                        </div>
                    )}</Col>
                </Row>
            </Container>


            </div>



        </>
    )

}


export default Order
